// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maincon {
  display: flex;
  margin-top: 100px;
  justify-content: center;
 
}

/* First Div Styling */
.div1con {
  width: 40%;
  background-color: rgba(246, 246, 254, 0.765);
  padding: 20px;
  border-radius: 1px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.479);
  transition: transform 0.3s ease;
}
.input{
  background-color: rgba(207, 207, 229, 0.765);

}

/* Second Div Styling */
.div2con {
  width: 40%;
  background: linear-gradient(rgba(99, 2, 144, 0.891), rgba(17, 0, 255, 0.395));
  color: white;
  padding: 20px;
  border-radius: 1px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.479); 
  transition: transform 0.3s ease;
}



/* Responsive Design */
@media (max-width: 576px) {
  .maincon {
    display: block;
    margin-top: 90px;
    padding: 0px;
  }

  .div1con,
  .div2con {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Contact.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,uBAAuB;;AAEzB;;AAEA,sBAAsB;AACtB;EACE,UAAU;EACV,4CAA4C;EAC5C,aAAa;EACb,kBAAkB;EAClB,4CAA4C;EAC5C,+BAA+B;AACjC;AACA;EACE,4CAA4C;;AAE9C;;AAEA,uBAAuB;AACvB;EACE,UAAU;EACV,6EAA6E;EAC7E,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,4CAA4C;EAC5C,+BAA+B;AACjC;;;;AAIA,sBAAsB;AACtB;EACE;IACE,cAAc;IACd,gBAAgB;IAChB,YAAY;EACd;;EAEA;;IAEE,WAAW;EACb;AACF","sourcesContent":[".maincon {\r\n  display: flex;\r\n  margin-top: 100px;\r\n  justify-content: center;\r\n \r\n}\r\n\r\n/* First Div Styling */\r\n.div1con {\r\n  width: 40%;\r\n  background-color: rgba(246, 246, 254, 0.765);\r\n  padding: 20px;\r\n  border-radius: 1px;\r\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.479);\r\n  transition: transform 0.3s ease;\r\n}\r\n.input{\r\n  background-color: rgba(207, 207, 229, 0.765);\r\n\r\n}\r\n\r\n/* Second Div Styling */\r\n.div2con {\r\n  width: 40%;\r\n  background: linear-gradient(rgba(99, 2, 144, 0.891), rgba(17, 0, 255, 0.395));\r\n  color: white;\r\n  padding: 20px;\r\n  border-radius: 1px;\r\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.479); \r\n  transition: transform 0.3s ease;\r\n}\r\n\r\n\r\n\r\n/* Responsive Design */\r\n@media (max-width: 576px) {\r\n  .maincon {\r\n    display: block;\r\n    margin-top: 90px;\r\n    padding: 0px;\r\n  }\r\n\r\n  .div1con,\r\n  .div2con {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
