// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 768px) {
    /* Adjust form layout for mobile */
    .form-container {
      flex-direction: column;
      padding: 10px;
    }
    
    .form-container div {
      width: 100%;
      margin-bottom: 15px;
    }
  
    /* Adjust input and textarea fields */
    input, textarea {
      width: 100%;
    }
  
    /* Adjust button alignment */
   
  
    /* Adjust Profiles Hosted section */
    .profiles-hosted {
      margin: 20px 0;
    }
  
    /* Adjust list items */
    ul li {
      margin-left: 0;
    }
  
    /* Adjust modal button alignment */
    .modal-footer button {
      width: 48%;
    }
  }
  @media(max-width:576px){
    .hrdata{
margin-left: -150px
    }
  }`, "",{"version":3,"sources":["webpack://./src/Component/database.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC;MACE,sBAAsB;MACtB,aAAa;IACf;;IAEA;MACE,WAAW;MACX,mBAAmB;IACrB;;IAEA,qCAAqC;IACrC;MACE,WAAW;IACb;;IAEA,4BAA4B;;;IAG5B,mCAAmC;IACnC;MACE,cAAc;IAChB;;IAEA,sBAAsB;IACtB;MACE,cAAc;IAChB;;IAEA,kCAAkC;IAClC;MACE,UAAU;IACZ;EACF;EACA;IACE;AACJ;IACI;EACF","sourcesContent":["@media only screen and (max-width: 768px) {\r\n    /* Adjust form layout for mobile */\r\n    .form-container {\r\n      flex-direction: column;\r\n      padding: 10px;\r\n    }\r\n    \r\n    .form-container div {\r\n      width: 100%;\r\n      margin-bottom: 15px;\r\n    }\r\n  \r\n    /* Adjust input and textarea fields */\r\n    input, textarea {\r\n      width: 100%;\r\n    }\r\n  \r\n    /* Adjust button alignment */\r\n   \r\n  \r\n    /* Adjust Profiles Hosted section */\r\n    .profiles-hosted {\r\n      margin: 20px 0;\r\n    }\r\n  \r\n    /* Adjust list items */\r\n    ul li {\r\n      margin-left: 0;\r\n    }\r\n  \r\n    /* Adjust modal button alignment */\r\n    .modal-footer button {\r\n      width: 48%;\r\n    }\r\n  }\r\n  @media(max-width:576px){\r\n    .hrdata{\r\nmargin-left: -150px\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
